import React from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';

import { selectTab } from '../store/actions';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import HomeIcon from '@material-ui/icons/Home';
import SaveIcon from '@material-ui/icons/Queue';
import HistoryIcon from '@material-ui/icons/History';

const styles = theme => ({
  nav: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  }
});

class InnerBottomNav extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <BottomNavigation showLabels className={classes.nav}
          value={this.props.selectedTab}
          onChange={(e,v) => this.props.selectTab(v)}>
        <BottomNavigationAction label="Home" value="home" icon={<HomeIcon/>} />
        <BottomNavigationAction label="Saved" value="saved" icon={<SaveIcon/>} />
        {/* <BottomNavigationAction label="History" value="history" icon={<HistoryIcon/>} /> */}
      </BottomNavigation>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedTab: state.selectedTab,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectTab: (tab) => dispatch(selectTab(tab)),
  }
}
const BottomNav = connect(mapStateToProps, mapDispatchToProps)(InnerBottomNav);
export default withStyles(styles)(BottomNav);
