import React from 'react';
import { connect } from "react-redux";
import { saveBusiness } from '../store/actions';

import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ButtonLink } from '../utils/utils';

const styles = theme => ({
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const default_business = {
  name: "",
  description: "",
  url: "",
  email: "",
};

class InnerBusiness extends React.PureComponent {
  state = {
    lastSave: null,
    business: this.props.business || {...default_business},
  }

  handleChange = (e, key, value=undefined) => {
    let business = {...this.state.business};
    business[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ business: business });
  };

  handleSave = e => {
    e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    this.props.save(this.state.business); 
    this.props.history.goBack();
  };


  render() {
    const { classes } = this.props;
    var title = "Add a new business";
    if(this.props.business) title = this.props.business.name;

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <h1>{title}</h1>
        <Typography variant="body1">
          Fill in the form below to add your business.
        </Typography>

        <form noValidate autoComplete="off" onSubmit={this.handleSave}>

          <TextField
            label="Business Name"
            name="name"
            required={true}
            className={classes.textField}
            value={this.state.business.name}
            onChange={ e => this.handleChange(e, "name") }
            margin="normal"
            fullWidth
          />

          <TextField
            label="Business Description"
            name="description"
            className={classes.textField}
            value={this.state.business.description}
            onChange={ e => this.handleChange(e, "description") }
            margin="normal"
            multiline={true}
            rows="3"
            fullWidth
          />

          <TextField
            label="Website"
            name="website"
            className={classes.textField}
            value={this.state.business.url}
            onChange={ e => this.handleChange(e, "url") }
            margin="normal"
            fullWidth
            helperText="Enter your website. E.g. https://www.mybusiness.com"
          />

          <TextField
            label="Email"
            name="email"
            className={classes.textField}
            value={this.state.business.email}
            onChange={ e => this.handleChange(e, "email") }
            margin="normal"
            fullWidth
          />
          <Button variant="contained" color="primary" className={classes.button}
                  type="submit" disabled={this.props.isSaving}>
            Save
          </Button>

          &nbsp;

          <Button variant="outlined" component={ButtonLink} to="/merchant">
            Back
          </Button>
        </form>
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    deals: state.deals,
    profile: state.profile,
    user: state.user,
    business: state.business,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    isFetchingProfile: state.isFetchingProfile,
    isFetchingDeals: state.isFetchingDeals,
    isSaving: state.saving,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    save: obj => {
      dispatch(saveBusiness(obj));
    }
  }
}
const Business = connect(mapStateToProps, mapDispatchToProps)(InnerBusiness);
export default withStyles(styles)(Business);

