import React from 'react';
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import { fetchDeals, saveForLater } from '../store/actions';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Queue';

import { Helmet } from 'react-helmet';
import DealPreview from './DealPreview';
import BottomNav from '../nav/BottomNav';

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  title: {
    textAlign: "left",
    marginBottom: 16,
  },
  description: {
    textAlign: "left",
    marginBottom: 16,
  },
  intro: {
    textAlign: "left",
    marginBottom: 16
  }
});

class InnerFeed extends React.PureComponent {
  componentDidMount () {
    console.log(`Loading feed for city ${this.props.city_slug}, business ${this.props.businessId}`);
    if(!this.props.deals)
      this.props.loadFeed(this.props.city_slug);
  }

  saveForLater = (dealId, saved) => {
    if(this.props.isLoggingIn) {
      console.log("SaveForLater: logging in, do nothing");
    } else if(this.props.isLoggedIn) {
      this.props.saveForLater(dealId, saved);
    } else {
      console.log("SaveForLater: not logged in, will redirect to login");
      this.props.history.push('/login');
    }

  };

  render() {
    const { classes } = this.props;
    var deals = (
      <Typography variant="body1" style={{marginTop: 40 }}>
        No offers and deals are available yet in your city
      </Typography>
    )
    var businessName = null;
    var businessDescription = null;
    var businessUrl = null;
    if(this.props.deals) {
      let filteredDeals = Object.values(this.props.deals);
      if(this.props.selectedTab=="saved") {
        filteredDeals = filteredDeals.filter(d => (this.props.saved && this.props.saved[d.deal_id]));

      } else if(this.props.businessId) {
        filteredDeals = filteredDeals.filter(d => (d.business && d.business.business_id==this.props.businessId));
        for(let d in filteredDeals) {
          businessName = filteredDeals[d].business.name;
          businessDescription = filteredDeals[d].business.description;
          businessUrl = filteredDeals[d].business.url;
          if(businessUrl && !businessUrl.startsWith('http')) businessUrl = 'http://' + businessUrl;
          break;
        }
      }
      // sort by novelty
      filteredDeals.sort( (d1, d2) => -(d1.created_at - d2.created_at) );
      // push expired deals to the bottom
      filteredDeals.sort( (d1, d2) => ((d1.expired ? 1 : 0) - (d2.expired ? 1 : 0)) );
      if(filteredDeals.length>0) {
        deals = filteredDeals.map( deal => {
          let saved = this.props.saved && this.props.saved[deal.deal_id];

          return (
            <DealPreview 
                key={deal.deal_id}
                deal={deal}
                saved={saved}
                saveForLater={ (e) => this.saveForLater(deal.deal_id, !saved) }
            />)
         });
      } else {
        if(this.props.selectedTab=="saved") {
          deals = (
            <Typography variant="body1" style={{marginTop: 40 }}>
              Use <SaveIcon /> to save your offers for later.
            </Typography>
          );
        }
      }
    }
    var title = `Club Coco Nut Plus | Offers, deals and promotions for children classes and activities - ${this.props.city_slug}`;
    if(businessName) title = businessName + " - " + title;
    let intro = null;
    if(this.props.selectedTab=="home" && !businessName) intro = (
      <Typography variant="body1" gutterBottom={true} className={classes.intro}>
        Club Coco Nut Plus curates offers and promotions from select providers of children activities,
        classes and events in Singapore.<br/> 
        <a className="std-link" href="mailto:plus@clubcoconut.co?subject=Club%20Coco%20Nut%20Plus">Email us</a> for any question.
      </Typography>

    );

    return (
      <div>
        <div className="feed" style={{ paddingBottom: 56 }}>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
          </Helmet>
          {intro}
          {businessName && <Typography variant="h4" className={classes.title}>{businessName}</Typography>}
          {businessDescription && <Typography variant="body1" className={classes.description}>{businessDescription}</Typography>}
          {businessUrl && <Typography variant="body1" className={classes.description}><a className="std-link" target="_blank" href={businessUrl}>{businessName}'s website</a></Typography>}
          {this.props.isFetchingDeals ? <CircularProgress style={{ marginTop: 40 }} /> : deals}
        </div>
        <BottomNav />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let businessId = null;
  if(ownProps.match && ownProps.match.params) businessId = ownProps.match.params.businessId;
  let saved = null;
  if(state.profile && state.profile.metadata && state.profile.metadata.saved)
    saved = {...state.profile.metadata.saved};

  return {
    city_slug: state.city_slug,
    selectedTab: state.selectedTab,
    businessId: businessId,
    deals: state.deals,
    profile: state.profile,
    saved: saved,
    user: state.user,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    isFetchingProfile: state.isFetchingProfile,
    isFetchingDeals: state.isFetchingDeals,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadFeed: (city, business=null, category=null) => {
      dispatch(fetchDeals(city, business, category));
    },
    saveForLater: (dealId, save) => {
      console.log(`Saving for later deal ${dealId} save ${save}`);
      dispatch(saveForLater(dealId, save));
    },
  }
}
const Feed = connect(mapStateToProps, mapDispatchToProps)(InnerFeed);
export default withStyles(styles)(Feed);
