import React from 'react';
import { connect } from "react-redux";
import { selectBusiness } from '../store/actions';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import NativeSelect from '@material-ui/core/NativeSelect';

import { Helmet } from 'react-helmet';
import DealPreview from '../feed/DealPreview';
import { ButtonLink } from '../utils/utils';

const styles = theme => ({
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

class InnerMerchant extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocation: null,
      businessLocation: null,
    };
  }

  handleChange = (e, key, value=undefined) => {
    this.setState({ [key]: (value!==undefined) ? value : e.target.value });
  };

  selectLocation = e => {
    let index = Number(e.target.value);
    this.setState({
      selectedLocation: index,
      businessLocation: this.props.business.locations[index],
    });
    
  };

  render() {
    const { classes, profile } = this.props;

    var businesses = (
      <Typography variant="body1">
        Add your business to get started.
      </Typography>
    )
    var locations = (
      <Typography variant="body1">
        Add one or more locations for your business.
      </Typography>
    )
    var deals = (
      <Typography variant="body1">
        Add promotions to drive customers to your business.
      </Typography>
    )

    if(profile && profile.businesses && profile.businesses.length>0) {

      businesses = (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>

          </TableHead>
          <TableBody>
            {profile.businesses.map( (biz, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Radio
                    name="radio_business"
                    value={biz.business_id}
                    checked={this.props.selectedBusiness==biz.business_id}
                    onChange={e => this.props.selectBusiness(biz.business_id)}
                  />
                </TableCell>
                <TableCell>{biz.name}</TableCell>
                <TableCell>{biz.description}</TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      );
    }

    if(this.props.business && this.props.business.locations && this.props.business.locations.length>0) {
      locations = (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Address</TableCell>
            </TableRow>

          </TableHead>
          <TableBody>
            {this.props.business.locations.map( (loc, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Radio
                    name="radio_location"
                    value={i}
                    checked={this.state.selectedLocation===i}
                    onChange={this.selectLocation}
                  />
                </TableCell>
                <TableCell>{loc.name}</TableCell>
                <TableCell>{loc.description}</TableCell>
                <TableCell>{loc.city_slug}, {loc.address}, {loc.postal_code}</TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      );
    }

    if(this.props.business && this.props.business.deals && this.props.business.deals.length>0) {
      this.props.business.deals.sort( (d1, d2) => -(d1.created_at - d2.created_at) );
      deals = this.props.business.deals.map( deal => <DealPreview key={deal.deal_id} deal={deal} showStatus={true} /> );
    }

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <Helmet>
          <title>Club Coco Nut Plus - Merchant Center</title>
          <meta name="description" content="Club Coco Nut Plus Merchant Center allows you to list your businesses, locations and promotions" />
        </Helmet>
        <h1>Merchant Center</h1>
        <Typography variant="body1">
          From this dashboard you can add your businesses and locations and manage your promotions.
        </Typography>

        <h2>Businesses</h2>
        <Button variant="outlined" startIcon={<AddIcon />} component={ButtonLink} to="/merchant/business/new">
          Add a business
        </Button>
        <div className={classes.section}>
          {this.props.isFetchingProfile ? <CircularProgress /> : businesses}
        </div>

        {this.props.business &&
          <React.Fragment>
            <h2>Locations of {this.props.business.name}</h2>
            <Button variant="outlined" startIcon={<AddIcon />} component={ButtonLink}
                to={`/merchant/business/${this.props.business.business_id}/new_location`}>
              Add a location
            </Button>
            <div className={classes.section}>
              {locations}
            </div>
          </React.Fragment>}

        {this.props.business &&
           <React.Fragment>
            <h2>Promotions of {this.props.business.name}</h2>
            <Button variant="outlined" startIcon={<AddIcon />} component={ButtonLink}
                to={`/merchant/business/${this.props.business.business_id}/new_deal`}>
              Add a promotion
            </Button>
            <div className={classes.section}>
              {deals}
            </div>
          </React.Fragment>}
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  let biz = null;
  if(state.profile && state.profile.businesses) {
    for(let i=0; i<state.profile.businesses.length; i++) {
      if(state.profile.businesses[i].business_id==state.selectedBusiness) {
        biz = state.profile.businesses[i];
        break;
      }
    }
  }
  return {
    profile: state.profile,
    business: biz,
    selectedBusiness: state.selectedBusiness,
    user: state.user,
    deals: state.deals,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    isFetchingProfile: state.isFetchingProfile,
    isFetchingDeals: state.isFetchingDeals,
    // lastRefresh: state.lastRefresh,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectBusiness: businessId => dispatch(selectBusiness(businessId)),
    /*
    deleteChannel: (channelId) => {
      dispatch(deleteChannel(channelId));
    },
    */
  }
}
const Merchant = connect(mapStateToProps, mapDispatchToProps)(InnerMerchant);
export default withStyles(styles)(Merchant);

