import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SaveIcon from '@material-ui/icons/Queue';
import EditIcon from '@material-ui/icons/Edit';

import moment from 'moment';
import { GCS_DOMAIN, ButtonLink, whatsappLink } from '../utils/utils';

const styles = theme => ({
  deal: {
    margin: "0px 0px 16px",
    textAlign: "left",
  },
  dealContent: {
    display: "flex",
  },
  media: {
    margin: "16px 0px 0px 8px",
    width: 100,
    //height: 0,
    // paddingTop: '56.25%', // 16:9
  },
  details: {
    width: "100%",
  },
  denseCard: {
    padding: 8,
  },
  actions: {
    width: "100%",
    padding: "0px 0px 4px 4px",
    "& button": {
      marginRight: 8,
    },
  },
  shop: {
    marginLeft: "auto",
    marginRight: 16,
  }
});

class InnerDealPreview extends React.PureComponent {
  render() {
    const { classes, deal } = this.props;
    // let image = "/logo.png";
    let media = (
      <Avatar>
        { deal.business.name ? deal.business.name[0].toUpperCase() : deal.name[0].toUpperCase() }
      </Avatar>
    );
    if(deal.thumb_urls && deal.thumb_urls.length>0) {
      let image = GCS_DOMAIN + deal.thumb_urls.split(',')[0];
      media = (<CardMedia image={image} title={deal.name} component="img" />);
    }
    // console.log(`DealPreview on ${JSON.stringify(deal)}, image ${image}`);
    let expiry = moment(deal.ends_at, 'X');
    let expireLabel = `Expires ${expiry.format('LL')}`;
    if(expiry<Date.now()) expireLabel = 'Expired';
    if(this.props.showStatus) {
      expireLabel = `[${deal.published ? "PUBLISHED" : "NOT PUBLISHED"}] [${deal.status.toUpperCase()}] ${expireLabel}`;
    }

    return (
      <Card className={classes.deal}>
        <div className={classes.dealContent}>
          <div className={classes.media}>
            <Link to={`/deal/${deal.deal_id}`}>
              {media}
            </Link>
          </div>
          <div className={classes.details}>
            <CardActionArea>
              <Link to={`/deal/${deal.deal_id}`}>
                <CardHeader className={classes.denseCard} title={deal.name} subheader={expireLabel} />
                <CardContent className={classes.denseCard} style={{paddingBottom: 8}}>
                  <Typography variant="body1" color="textSecondary" component="p">
                    Offered by <b>{deal.business.name}</b>
                  </Typography>
                </CardContent>
              </Link>
            </CardActionArea>
          </div>
        </div>
        <CardActions className={classes.actions} disableSpacing>
          <Button aria-label="save for later"
              onClick={this.props.saveForLater} 
              color={this.props.saved ? "secondary" : "default"}
              startIcon={<SaveIcon />} >
            Save
          </Button>
          <Button aria-label="share"
              href={whatsappLink(deal.name, 'https://plus.clubcoconut.co/deal/' + deal.deal_id)}
              startIcon={<WhatsAppIcon />} >
            Share
          </Button>
          {this.props.showStatus ? (
            <Button className={classes.shop} color="primary"
                aria-label="edit"
                component={ButtonLink}
                to={`/merchant/business/${deal.business_id}/deal/${deal.deal_id}`}
                startIcon={<EditIcon />} >
              Edit
            </Button>) : (
            <Button className={classes.shop} color="primary"
                aria-label="learn more"
                component={ButtonLink} to={`/deal/${deal.deal_id}`} >
              Learn More
            </Button> )}
        </CardActions>
      </Card>
    );
  }

}

export default withStyles(styles)(InnerDealPreview);
