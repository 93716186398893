import React from 'react';
import { useEffect } from "react";
import { Link } from 'react-router-dom';

export var GCS_DOMAIN = 'https://storage.googleapis.com';
export var STRIPE_KEY = 'pk_live_gGc0oxgA5QQVMekQrhMjvU5J';
export var STRIPE_SKU = 'sku_GRpqx5TPiJ02bJ';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  GCS_DOMAIN = 'http://localhost:8080/_ah/gcs'; 
  STRIPE_KEY = 'pk_test_brpI5tmF0IvOHu1T54UrPA9u';
  STRIPE_SKU = 'sku_GRqcilPnCE9Ebg';
}
export const ButtonLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

export const CITIES = [
  { value: "singapore", display: "Singapore" },
  { value: "hong_kong", display: "Hong Kong" },
  { value: "kuala_lumpur", display: "Kuala Lumpur" },
  { value: "johor_bahru", display: "Johor Bahru" },
  { value: "jakarta", display: "Jakarta" },
  { value: "Bangkok", display: "Bangkok" },
  { value: "manila", display: "Manila" },
];

export const CATEGORIES = [
  { value: "events", display: "Events" },
  { value: "classes", display: "Classes" },
  { value: "outdoor", display: "Outdoor" },
  { value: "toys", display: "Toys" },
  { value: "digital", display: "Apps and Software" },
  { value: "other", display: "Other" },
];

export const whatsappLink = (text, url) => (`https://wa.me/?text=${encodeURIComponent(text+" "+url)}`);


export function ScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
