import {
  SIGNIN_EVENT, SAVE_TOKEN, RECEIVE_GEO, RECEIVE_PROFILE, REQUEST_PROFILE,
  REQUEST_DEALS, RECEIVE_DEALS, REQUEST_CODE, RECEIVE_CODE,
  OPEN_STATUS_MESSAGE, CLOSE_STATUS_MESSAGE, SELECT_TAB, SELECT_BUSINESS,
  SAVING,
} from './actions';

const test_deals = [
  { deal_id: "1",
    name: "Bel deal",
    description: "Get 15% OFF",
    image: "https://admin.giladiskon.com/images/page-attribute/promo%20bioskop.png",
    category: "toys",
    starts_at: "2019-11-01",
    ends_at: "2019-11-30",
  },

  { deal_id: "2",
    name: "Bel deal secondo",
    description: "Get 20% OFF",
    image: "https://admin.giladiskon.com/images/page-attribute/promo%20e-commerce%20.png",
    category: "classes",
    starts_at: "2019-11-01",
    ends_at: "2019-11-30",
  }
];

const initialState = {
  selectedTab: "home",
  geo: null,
  language: null,
  user: null,
  profile: null,
  businesses: null,
  city_slug: "singapore",
  deals: null,
  redemptions: {},
  loggedIn: false,
  loggingIn: true,
  token: null,
  statusMessage: null,
  openStatusMessage: false,
  isFetchingProfile: true,
  isFetchingDeals: true,
  isRedeeming: false,
  saving: false,
};

const rootReducer = (state = initialState, action) => {
  switch(action.type) {
    case SIGNIN_EVENT:
      if(action.user) {
        console.log(`Signing in with user ${JSON.stringify(action.user)}`);
        let new_profile = {...state.profile};
        new_profile.name = new_profile.name || action.user.displayName;
        new_profile.email = new_profile.email || action.user.email;
        new_profile.number = new_profile.number || action.user.phoneNumber;
        return { ...state, loggedIn: true, loggingIn: false, user: action.user, profile: new_profile, };
      } else {
        console.log("User not logged in");
        return { ...state, loggingIn: false, loggedIn: false };
      }

    case SAVE_TOKEN:
      return { ...state, token: action.token };

    case REQUEST_PROFILE:
      return { ...state, isFetchingProfile: true, };

    case RECEIVE_PROFILE:
      return { ...state, profile: {...action.profile }, isFetchingProfile: false, lastRefresh: Date.now()};

    case RECEIVE_GEO:
      return { 
          ...state, 
          geo: action.geo,
          // currency: (action.geo && action.geo.country) ? getCurrency(action.geo.country) : null,
          language: action.language 
      };

    case REQUEST_DEALS:
      return { ...state, isFetchingDeals: true };

    case RECEIVE_DEALS: {
      var deals = {};
      if(!action.deals || action.deals.length===0) return state;
      for(let i=0; i<action.deals.length; i++) {
        deals[action.deals[i].deal_id] = action.deals[i];
      }
      var newState = { ...state, deals: Object.assign(state.deals || {}, deals), isFetchingDeals: false, };
      return newState;
    }

    case REQUEST_CODE:
      return { ...state, isRedeeming: true };

    case RECEIVE_CODE: {
      console.log(`RECEIVE_CODE: deal ${action.deal.deal_id}, redemption ${JSON.stringify(action.redemption)}`);
      let redemptions = {...state.redemptions};
      redemptions[action.deal.deal_id] = action.redemption;
      return {...state, redemptions: redemptions, isRedeeming: false};
    }

    case OPEN_STATUS_MESSAGE:
      return { ...state, openStatusMessage: true, statusMessage: action.message };

    case CLOSE_STATUS_MESSAGE:
      return { ...state, openStatusMessage: false, statusMessage: null };

    case SELECT_TAB:
      console.log(`Setting tab ${action.tab}`);
      return { ...state, selectedTab: action.tab }

    case SAVING:
      return { ...state, saving: action.isSaving };

    case SELECT_BUSINESS:
      return { ...state, selectedBusiness: action.businessId };

    default:
      return state;
  }
};

export default rootReducer;

