import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

class NotFound extends React.PureComponent {
  render() {
    return (
      <div className="feed stdLink" style={{textAlign: "left", padding: "30px 0px"}}>
        <Helmet>
          <title>Club Coco Nut Plus - Page Not Found</title>
          <meta name="og:title" content="Club Coco Nut Plus - Page Not Found" />
        </Helmet>

        <Typography variant="h3">
          Page not found.
        </Typography>
        <div style={{margin: 20}}>&nbsp;</div>
        <Typography>
          Go back <Link to="/">home</Link>.
        </Typography>
      </div>
    );
  }
}

export default NotFound;
