import React from 'react';
import { connect } from "react-redux";
import { saveLocation } from '../store/actions';

import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

import { ButtonLink, CITIES } from '../utils/utils';

const styles = theme => ({
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginRight: 20,
  }
});

const default_location = {
  name: "",
  description: "",
  address: "",
  postal_code: "",
  city_slug: "singapore",
};

class InnerLocation extends React.PureComponent {
  state = {
    lastSave: null,
    businessLocation: this.props.businessLocation || {...default_location},
  }

  handleChange = (e, key, value=undefined) => {
    let businessLocation = {...this.state.businessLocation};
    businessLocation[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ businessLocation: businessLocation });
  };

  handleSave = e => {
    e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    this.props.save(this.state.businessLocation); 
    this.props.history.goBack();
  };


  render() {
    const { classes } = this.props;
    var title = "Add a new location";
    if(this.props.businessLocation) title = this.props.businessLocation.name;

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <h1>{title}</h1>
        <Typography variant="body1">
          Fill in the form below to add your location.
        </Typography>

        <form noValidate autoComplete="off" onSubmit={this.handleSave}>

          <TextField
            label="Location Name"
            name="name"
            required={true}
            className={classes.textField}
            value={this.state.businessLocation.name}
            onChange={ e => this.handleChange(e, "name") }
            margin="normal"
            fullWidth
          />

          <TextField
            label="Location Description"
            name="description"
            className={classes.textField}
            value={this.state.businessLocation.description}
            onChange={ e => this.handleChange(e, "description") }
            margin="normal"
            multiline={true}
            rows="3"
            fullWidth
          />

          <FormControl>
            <InputLabel>City</InputLabel>
            <NativeSelect
              value={this.state.businessLocation.city_slug}
              onChange={ e => this.handleChange(e, "city_slug") }
            >
              {CITIES.map( (city, i) => <option key={i} value={city.value}>{city.display}</option> )}
            </NativeSelect>
          </FormControl>

          <FormGroup row>
            <TextField
              label="Address"
              name="address"
              className={classes.textField}
              value={this.state.businessLocation.address}
              onChange={ e => this.handleChange(e, "address") }
              margin="normal"
            />

            <TextField
              label="Postal code"
              name="postal_code"
              className={classes.textField}
              value={this.state.businessLocation.postal_code}
              onChange={ e => this.handleChange(e, "postal_code") }
              margin="normal"
            />
          </FormGroup>

          <div className={classes.section}>
            <Button variant="contained" color="primary" className={classes.button}
                    type="submit" disabled={this.props.isSaving}>
              Save
            </Button>

            &nbsp;

            <Button variant="outlined" component={ButtonLink} to="/merchant">
              Back
            </Button>
          </div>
        </form>
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    deals: state.deals,
    profile: state.profile,
    user: state.user,
    business: state.business,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    isFetchingProfile: state.isFetchingProfile,
    isFetchingDeals: state.isFetchingDeals,
    isSaving: state.saving,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    save: obj => {
      dispatch(saveLocation(ownProps.match.params.businessId, obj));
    }
  }
}
const Location = connect(mapStateToProps, mapDispatchToProps)(InnerLocation);
export default withStyles(styles)(Location);

