import React from 'react';
import { connect } from "react-redux";
import { saveDeal, fetchDeal, fetchDeals } from '../store/actions';

import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import { ButtonLink, CITIES, CATEGORIES } from '../utils/utils';
import moment from 'moment';

const styles = theme => ({
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginRight: 16,
  },
  formControl: {
    marginTop: 16,
  }
});

var today = new Date();

class ImageCaptureButton extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Button variant="contained" component="label">
        <CameraAltIcon className={classes.leftIcon} />
        Promotion Image
        <input id="capture-image" 
          type="file" accept="image/*"
          style={{ display: 'none' }}
          onChange={this.props.handleFiles}
        />
      </Button>
    );
  }
}

ImageCaptureButton = withStyles(styles)(ImageCaptureButton);


const default_deal = {
  published: true,
  name: "",
  description: "",
  url: "",
  city_slug: "singapore",
  code_type: "same",
  category: "classes",
  voucher_code: "",
  starts_at: moment().format("YYYY-MM-DD"),
  ends_at: moment().add(90, 'days').format("YYYY-MM-DD"),
};

class InnerDeal extends React.PureComponent {
  /*
  constructor(props) {
    super(props);
    var deal = null;
    var biz = null;
    console.log(`InnerDeal.constructor() with profile ${JSON.stringify(this.props.profile)}`);
    if(this.props.match && this.props.match.params && this.props.match.params.dealId && this.props.match.params.businessId) {
      if(this.props.profile && this.props.profile.businesses && this.props.profile.businesses.length>0) {
        for(let i=0; i<this.props.profile.businesses.length; i++) {
          if(this.props.profile.businesses[i].business_id == this.props.match.params.businessId) {
            console.log(`Checking business ${this.props.profile.businesses[i].business_id} vs url ${this.props.match.params.businessId}`);
            biz = this.props.profile.businesses[i];
            break;
          }
        }
      }
      if(biz && biz.deals && biz.deals.length>0) {
        for(let i=0; i<biz.deals.length; i++) {
          if(biz.deals[i].deal_id == this.props.match.params.dealId) {
            console.log(`Checking deal ${biz.deals[i].deal_id} vs url ${this.props.match.params.dealId}`);
            deal = biz.deals[i];
            break;
          }
        }
      } else {
        console.log(`No deals for biz ${JSON.stringify(biz)}`);
      }
    }
    this.state = {
      lastSave: null,
      business: biz,
      deal: deal || this.props.deal || {...default_deal},
      images: [],
    };

  }*/

  constructor(props) {
    super(props);
    let reformatDeal = null;
    if(this.props.deal) {
      reformatDeal = {
        ...this.props.deal,
        starts_at: moment(this.props.deal.starts_at, 'X').format('YYYY-MM-DD'),
        ends_at: moment(this.props.deal.ends_at, 'X').format('YYYY-MM-DD'),
        description: this.props.deal.description || "",
        voucher_code: this.props.deal.voucher_code || "",
        url: this.props.deal.url || "",
      }
    }

    this.state = {
      images: [],
      deal: reformatDeal || {...default_deal},
      lastSave: null,
    }
  }

  handleFiles = (e) => {
    this.setState({images: e.target.files});
    console.log(`Adding files ${JSON.stringify(e.target.files)}`);
  };

  handleChange = (e, key, value=undefined) => {
    let deal = {...this.state.deal};
    deal[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ deal: deal });
  };

  handleSave = e => {
    e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    this.props.save(this.state.deal, this.state.images); 
    this.props.history.goBack();
  };


  render() {
    const { classes } = this.props;
    var title = "Add a new promotion";
    var intro = "Fill in the form below to add your promotion."
    if(this.props.deal) {
      title = "Edit promotion";
      intro = "Fill in the form below to update your promotion.";
    }
    console.log(`Deal published ${this.state.deal.published}`);

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <h1>{title}</h1>
        <Typography variant="body1">
          {intro}
        </Typography>

        <form noValidate autoComplete="off" onSubmit={this.handleSave}>

          <FormControlLabel
            control={
              <Switch
                checked={this.state.deal.published}
                onChange={ e => this.handleChange(e, "published", !this.state.deal.published) }
                value="published"
                color="primary"
              />
            }
            label="Published"
          />

          <TextField
            label="Promotion name or title"
            name="name"
            helperText="Example: 20% OFF all dolls"
            required={true}
            className={classes.textField}
            value={this.state.deal.name}
            onChange={ e => this.handleChange(e, "name") }
            margin="normal"
            fullWidth
          />

          <TextField
            label="Promotion description and terms "
            name="description"
            helperText="Example: only until Dec 31st, 20% OFF all our dolls. One redemption per customer."
            required={true}
            className={classes.textField}
            value={this.state.deal.description}
            onChange={ e => this.handleChange(e, "description") }
            margin="normal"
            multiline={true}
            rows="3"
            fullWidth
          />

          <div className={classes.section}>
            <FormControl>
              <InputLabel>City</InputLabel>
              <NativeSelect
                value={this.state.deal.city_slug}
                onChange={ e => this.handleChange(e, "city_slug") }
              >
                {CITIES.map( (city, i) => <option key={i} value={city.value}>{city.display}</option> )}
              </NativeSelect>
            </FormControl>
          </div>

          <div className={classes.section}>
            <FormControl>
              <InputLabel>Category</InputLabel>
              <NativeSelect
                value={this.state.deal.category}
                onChange={ e => this.handleChange(e, "category") }
              >
                {CATEGORIES.map( (cat, i) => <option key={i} value={cat.value}>{cat.display}</option> )}
              </NativeSelect>
            </FormControl>
          </div>

          <FormGroup>
            <FormControl>
              <InputLabel>Voucher code type</InputLabel>
              <NativeSelect
                value={this.state.deal.code_type}
                onChange={ e => this.handleChange(e, "code_type") }
              >
                <option value="same">One code, same for all customers</option>
                <option value="unique">Unique codes, one per customer</option>
              </NativeSelect>
            </FormControl>

            <TextField
              label="Voucher Code"
              name="voucher_code"
              className={classes.textField}
              helperText="Example: WELCOME20"
              value={this.state.deal.voucher_code}
              onChange={ e => this.handleChange(e, "voucher_code") }
              margin="normal"
              disabled={this.state.deal.code_type!=="same"}
            />
          </FormGroup>

          <FormGroup row>
            <TextField
              label="Valid from"
              name="valid_from"
              type="date"
              className={classes.textField}
              value={this.state.deal.starts_at}
              onChange={ e => this.handleChange(e, "starts_at") }
              margin="normal"
            />

            <TextField
              label="Valid until"
              name="valid_until"
              type="date"
              className={classes.textField}
              value={this.state.deal.ends_at}
              onChange={ e => this.handleChange(e, "ends_at") }
              margin="normal"
            />
          </FormGroup>

          <TextField
            label="URL (optional)"
            name="url"
            helperText="Example: https://www.mytoystore.com/promo20"
            required={false}
            className={classes.textField}
            value={this.state.deal.url}
            onChange={ e => this.handleChange(e, "url") }
            margin="normal"
            fullWidth
          />

          <FormGroup>
            <FormControl className={classes.formControl}>
              <ImageCaptureButton handleFiles={ this.handleFiles } />
              <FormHelperText>{ this.state.images && Object.values(this.state.images).map(x => x.name).join(", ") }</FormHelperText>
            </FormControl>
          </FormGroup>


          <div className={classes.section}>
            <Button variant="contained" color="primary" className={classes.button}
                    type="submit" disabled={this.props.isSaving}>
              Save
            </Button>

            &nbsp;

            <Button variant="outlined" component={ButtonLink} to="/merchant">
              Back
            </Button>
          </div>
        </form>

        <Typography variant="body2">
          We will shortly review your promotion and let you know if it is approved.
          Promotions will appear to the end customer only after the <code>Valid from</code> date.
        </Typography>
        
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let deal = null;
  return {
    deals: state.deals,
    city_slug: state.city_slug,
    profile: state.profile,
    user: state.user,
    business: state.business,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    isFetchingProfile: state.isFetchingProfile,
    isFetchingDeals: state.isFetchingDeals,
    isSaving: state.saving,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    save: (obj, images) => {
      dispatch(saveDeal(ownProps.match.params.businessId, obj, images));
    },
  }
}
const Deal = connect(mapStateToProps, mapDispatchToProps)(InnerDeal);
export default withStyles(styles)(Deal);

