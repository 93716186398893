import React from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';

import { fetchDeal, openStatusMessage } from '../store/actions';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Queue';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import { GCS_DOMAIN, whatsappLink, STRIPE_KEY, STRIPE_SKU } from '../utils/utils';
import { StripeProvider, injectStripe, Elements } from 'react-stripe-elements';
import moment from 'moment';

const styles = theme => ({
  dialog: {
    margin: 8,
  },
  payBtn: {
    margin: 24,
    textAlign: "center",
  },
});

// const stripe = Stripe(STRIPE_KEY);

class InnerCheckout extends React.PureComponent {
  stripeCheckout = (e) => {
    console.log(`Initiating Stripe Checkout with user ${JSON.stringify(this.props.profile)}`);
    ReactGA.event({
      category: "checkout",
      action: "initiated",
      label: this.props.profile ? this.props.profile.user_id : "No user",
    });
    ReactPixel.track("InitiateCheckout");
    this.props.stripe.redirectToCheckout({
      items: [
        {sku: STRIPE_SKU, quantity: 1}
      ],
      successUrl: window.location.href + '#success',
      cancelUrl: window.location.href + '#cancel',
      clientReferenceId: this.props.profile.user_id,
    }).then(function (result) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      this.props.statusMessage(result.error.message);
    });

  }

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog open={open} onClose={onClose} classes={{paper: classes.dialog}}>
        <DialogTitle>
          {this.props.expired
            ? `Your Club Coco Nut Plus subscription expired on ${moment(this.props.expiry).format('LL')}`
            : "Upgrade to Club Coco Nut Plus"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Club Coco Nut Plus gives you access to the best promotions for children activities and content.
          </DialogContentText>

          <DialogContentText>
            Save over 60% and buy your yearly Club Coco Nut Plus membership
          </DialogContentText>

          <DialogContentText style={{ textAlign: "center" }}>
            at <del style={{fontSize: "125%"}}>S$50</del> <strong style={{fontSize: "125%"}}>S$18</strong> only!
          </DialogContentText>

          <div className={classes.payBtn}>
            <Button color="primary" variant="contained" onClick={this.stripeCheckout}>
              Buy Now
            </Button>
          </div>

        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.profile,
    user: state.user,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    statusMessage: (text) => {
      dispatch(openStatusMessage(text));
    },
  }
}
const Checkout = injectStripe(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InnerCheckout)));

function UpgradeDialog(props) {
  return (
      <StripeProvider apiKey={STRIPE_KEY}>
        <Elements>
          <Checkout open={props.open} onClose={props.onClose} />
        </Elements>
      </StripeProvider>
  );
}

export default UpgradeDialog;
