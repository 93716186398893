import React from 'react';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchDeal, fetchDeals } from '../store/actions';
import Deal from './Deal';

class InnerEditDeal extends React.PureComponent {

  componentDidMount() {
    if(!this.props.deal) {
      this.props.loadDeal(this.props.city_slug);
    }
  }

  render() {
    let form = (
      <div className="feed">
        <CircularProgress style={{marginTop: 40}} />
      </div>);

    if(this.props.deal && !this.props.isFetchingDeals) {
      form = ( <Deal deal={this.props.deal} match={this.props.match} history={this.props.history} /> );
    }

    return form;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { dealId } = ownProps.match.params;

  let deal = null;
  if(state.deals && dealId in state.deals) deal = state.deals[dealId];

  return {
    deal: deal,
    city_slug: state.city_slug,
    profile: state.profile,
    user: state.user,
    isFetchingDeals: state.isFetchingDeals,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadDeal: (city) => {
      console.log(`EditDeal.loadDeal: Loading deal ${ownProps.match.params.dealId}`);
      dispatch(fetchDeal(ownProps.match.params.dealId));
      setTimeout(() => dispatch(fetchDeals(city, ownProps.match.params.businessId)), 500);
    },
  }
}
const EditDeal = connect(mapStateToProps, mapDispatchToProps)(InnerEditDeal);
export default EditDeal;

