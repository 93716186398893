import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { fetchDeal, fetchDeals, redeemDeal, openStatusMessage, saveForLater } from '../store/actions';

import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Queue';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import RedeemIcon from '@material-ui/icons/Redeem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Signin from '../auth/Signin';
import UpgradeDialog from './UpgradeDialog';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { GCS_DOMAIN, whatsappLink } from '../utils/utils';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { ScrollToTop } from '../utils/utils';

const styles = theme => ({
  dialog: {
    margin: 8,
  },
  deal: {
    margin: "0px 0px 16px",
    textAlign: "left",
  },
  dealContent: {
    display: "flex",
  },
  media: {
    margin: "16px 0px 0px 0px",
    width: 100,
    //height: 0,
    // paddingTop: '56.25%', // 16:9
  },
  details: {
    width: "100%",
    padding: "8px 8px 8px 16px",
  },
  description: {
    margin: "8px 0px"
  },
  actions: {
    width: "100%",
    padding: "4px 0px",
    marginTop: 16,
    display: "flex",
    "& button": {
      marginRight: 8,
    }
  },
  shop: {
    marginLeft: "auto",
    marginRight: 16,
  },
  helper: {
    marginTop: 16,
  },
});

function InnerLoginDialog(props) {

  return (
    <Dialog open={props.open} onClose={props.onClose} classes={{paper: props.classes.dialog}}>
      <DialogTitle>Sign in</DialogTitle>
      <DialogContent>
        <Signin />
      </DialogContent>
    </Dialog>
  );
}

const LoginDialog = withStyles(styles)(InnerLoginDialog);


class InnerDealPage extends React.PureComponent {
  state = {
    openLogin: false,
    openUpgrade: false,
    expired: false,
    expiry: null,
  };

  componentDidMount() {
    if(!this.props.deal) {
      this.props.loadDeal(this.props.city_slug);
    }
    const { location } = this.props.history;
    if(location && location.hash) {
      console.log(`Found hash ${location.hash}`);
      switch(location.hash) {
        case '#success':
          console.log(`Firing success pixel!`);
          ReactGA.event({
            category: "checkout",
            action: "success",
            label: this.props.profile ? this.props.profile.user_id : "No user",
          });
          ReactPixel.track("Purchase", {
            currency: "SGD", 
            value: 18.0, 
            content_ids: [this.props.dealId], 
            content_type: "product",
            contents: [{id: this.props.dealId, quantity: 1}],
          });
          break;
        case '#cancel':
          console.log("Firing cancel pixel!");
          ReactGA.event({
            category: "checkout",
            action: "cancel",
            label: this.props.profile ? this.props.profile.user_id : "No user",
          });
          ReactPixel.trackCustom("checkout_canceled");
          break;
        default:
          console.log("Hash not recognized, do nothing");
      }
    }
    ReactPixel.track("ViewContent", {
        content_ids: ["deal_" + this.props.dealId],
        content_type: "product",
        contents: [{id: "deal_" + this.props.dealId, quantity: 1}],
    });
  }

  save = (e) => {
    if(this.props.isLoggedIn) {
      console.log(`User ${this.props.user.uid} ${this.props.user.phoneNumber} wants to save offer for later`);
      this.props.saveForLater(!this.props.saved);
    } else {
      console.log("User is not logged in, open sign in dialog");
      this.setState({openLogin: true});
    }
  }

  redeem = (e) => {
    if(this.props.isLoggedIn) {
      console.log(`User ${this.props.user.uid} ${this.props.user.phoneNumber} wants to redeem`);
      if(this.props.profile) {
        console.log(`User has plan ${this.props.profile.plan} expiring ${this.props.profile.plan_expiry}`);
        if(this.props.profile.plan==='free') {
          console.log("User has free plan, opening payment dialog");
          ReactPixel.track("AddToCart", {
            content_ids: [this.props.dealId],
            content_type: "product",
            contents: [{id: this.props.dealId, quantity: 1}],
          });
          this.setState({openUpgrade: true});
        } else {
          let expiry = moment(this.props.profile.plan_expiry, 'X');
          if(expiry>Date.now()) {
            console.log("User can redeem code, show code");
            this.props.redeemDeal();
          } else {
            console.log("User's plan expired! Opening payment dialog");
            ReactPixel.track("AddToCart", {
              content_ids: [this.props.dealId],
              content_type: "product",
              contents: [{id: this.props.dealId, quantity: 1}],
            });
            this.setState({openUpgrade: true, expired: true, expiry: expiry});
          }
        }
      }
    } else {
      console.log("User is not logged in, open sign in dialog");
      this.setState({openLogin: true});
    }

  };

  closeLogin = () => {
    this.setState({openLogin: false});
  }

  closeUpgrade = () => {
    this.setState({openUpgrade: false});
  }

  mapLink = (query, w1=null, w2=null) => {
    if(w1) query += " " + w1;
    if(w2) query += " " + w2;
    return "https://maps.google.com/?q=" + encodeURIComponent(query);
  }

  render() {
    const { classes, deal } = this.props;
    let card = (<CircularProgress style={{marginTop: 40}} />);

    if(deal && !this.props.isFetchingDeals) {
      let image = "https://plus.clubcoconut.co/logo.png";
      let large_image = "https://plus.clubcoconut.co/logo.png";
      let media = (
        <Avatar>
          { deal.business.name ? deal.business.name[0].toUpperCase() : deal.name[0].toUpperCase() }
        </Avatar>
      );
      if(deal.thumb_urls && deal.thumb_urls.length>0) {
        image = GCS_DOMAIN + deal.thumb_urls.split(',')[0];
        media = (<img src={image} alt={deal.name} />);
      }
      if(deal.image_urls && deal.image_urls.length>0) {
        large_image = GCS_DOMAIN + deal.image_urls.split(',')[0];
      }
      // console.log(`DealPage on ${JSON.stringify(deal)}, image ${image}`);
      let expiry = moment(deal.ends_at, 'X');
      let expireLabel = `Expires ${expiry.format('LL')}`;
      if(expiry<Date.now()) expireLabel = 'Expired';

      let reedemBtn = (
        <Button className={classes.shop} color="primary" variant="outlined" startIcon={<LocalOfferIcon />}
            disabled={expiry<Date.now() || ((this.props.isLoggedIn || this.props.isLoggingIn) && (!this.props.profile || !this.props.profile.plan || this.props.isRedeeming))}
            onClick={this.redeem}>
          {this.props.isRedeeming ? "Redeeming..." : (expiry<Date.now() ? "Expired" : "Redeem")}
        </Button>
      );
      if(this.props.redemption) {
        console.log(`Deal has already been redeemed, showing code instead`);
        reedemBtn = (
          <Button className={classes.shop} color="primary" startIcon={<RedeemIcon />}
              variant="outlined" href={deal.url}>
            {this.props.redemption.voucher_code}
          </Button>
        );
      }
      let locations = null;
      if(deal.business.locations) locations = deal.business.locations.map( loc => (
        <li key={loc.location_id}>
          {loc.name} - {loc.address} {loc.city} {loc.postal_code}
          &nbsp;
          [<a className="std-link" href={this.mapLink(loc.address, loc.city, loc.postal_code)}>map</a>]
        </li>) );

      var json_ld = {
          "@context": "http://schema.org",
          "@type": "Product",
          "productID": "deal_" + deal.deal_id,
          "name": deal.name,
          "description": deal.description,
          "url": `https://plus.clubcoconut.co/deal/${deal.deal_id}`,
          "image": large_image || image,
          "brand": deal.business.name,
          "offers":[{
            "@type":"Offer",
            "price":"18.0",
            "priceCurrency":"SGD",
            "itemCondition":"https://schema.org/NewCondition",
            "availability":"https://schema.org/InStock"
            }]
      };

      card=(
        <div className={classes.deal}>
          <ScrollToTop />
          
          <Helmet>
            <title>{deal.business.name} - {deal.name} | Club Coco Nut Plus</title>
            <meta name="description" content={deal.description} />
            <script type="application/ld+json">
              {JSON.stringify(json_ld)}
            </script>
          </Helmet>

          <Typography variant="h4" gutterBottom>{deal.name}</Typography>
          <Typography variant="body2">{expireLabel}</Typography>
          <div className={classes.dealContent}>
            <div className={classes.media}>
              {media}
            </div>
            <div className={classes.details}>
              <div className={classes.description}>
                <Typography variant="body1" color="textPrimary" component="p">
                  Offered by <Link to={`/business/${deal.business.business_id}`} className="std-link"><b>{deal.business.name}</b></Link>
                </Typography>
                {locations && <ul>{locations}</ul>}
              </div>
            </div>
          </div>
          <div className={classes.description}>
            <Typography variant="body1" color="textSecondary" component="p">
              {deal.description}
            </Typography>
            <div className={classes.actions}>
              <Button aria-label="save for later"
                  onClick={this.save} 
                  color={this.props.saved ? "secondary" : "default"}
                  startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button aria-label="share"
                  href={whatsappLink(deal.name, window.location.href)}
                  startIcon={<WhatsAppIcon />}>
                Share
              </Button>
              {reedemBtn}
            </div>
          </div>
          <div className={classes.helper}>
            <Typography variant="body2" color="textSecondary">
              Click on 'Redeem' to show the promotion code.
              { !this.props.isLoggedIn && " You will be required to login using your phone number." }
              { !this.props.subscribed && " If you are not a Club Coco Nut Plus member, you will be asked to join." }
            </Typography>
          </div>
        </div>
      );
    };

    return (
      <div>
        <div className="feed">
          {card}
        </div>
        <LoginDialog open={this.state.openLogin} onClose={this.closeLogin} />
        <UpgradeDialog
            open={this.state.openUpgrade} onClose={this.closeUpgrade}
            expired={this.state.expired} expiry={this.state.expiry} />
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  const { dealId } = ownProps.match.params;

  let deal = null;
  if(state.deals && dealId in state.deals) deal = state.deals[dealId];
  let redemption = null;
  if(state.redemptions && dealId in state.redemptions) redemption = state.redemptions[dealId];
  let saved = false;
  if(state.profile && state.profile.metadata && state.profile.metadata.saved)
    saved = state.profile.metadata.saved[dealId];
  let subscribed = false;
  if(state.profile && state.plan_expiry) {
    let expiry = moment(state.profile.plan_expiry, 'X');
    subscribed = expiry>Date.now();
  }

  return {
    deals: state.deals,
    deal: deal,
    dealId: dealId,
    saved: saved,
    redemption: redemption,
    profile: state.profile,
    subscribed: subscribed,
    user: state.user,
    city_slug: state.city_slug,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    isFetchingProfile: state.isFetchingProfile,
    isFetchingDeals: state.isFetchingDeals,
    isRedeeming: state.isRedeeming,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadDeal: (city) => {
      console.log(`Loading deal ${ownProps.match.params.dealId}`);
      dispatch(fetchDeal(ownProps.match.params.dealId));
      setTimeout(() => dispatch(fetchDeals(city)), 500);
    },
    redeemDeal: () => {
      console.log(`Redeeming deal ${ownProps.match.params.dealId}`);
      dispatch(redeemDeal(ownProps.match.params.dealId));
    },
    saveForLater: (save) => {
      console.log(`Saving for later deal ${ownProps.match.params.dealId}`);
      dispatch(saveForLater(ownProps.match.params.dealId, save));
    }
  }
}
const DealPage = connect(mapStateToProps, mapDispatchToProps)(InnerDealPage);
export default withStyles(styles)(DealPage);
