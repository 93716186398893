import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
// import 'firebase/analytics';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

export const SIGNIN_EVENT = "SIGNIN_EVENT";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const RECEIVE_GEO = "RECEIVE_GEO";
export const REQUEST_PROFILE = "REQUEST_PROFILE";
export const RECEIVE_PROFILE = "RECEIVE_PROFILE";

export const REQUEST_DEALS = "REQUEST_DEALS";
export const RECEIVE_DEALS = "RECEIVE_DEALS";

export const REQUEST_CODE  = "REQUEST_CODE";
export const RECEIVE_CODE  = "RECEIVE_CODE";

export const OPEN_STATUS_MESSAGE = "OPEN_STATUS_MESSAGE";
export const CLOSE_STATUS_MESSAGE = "CLOSE_STATUS_MESSAGE";
export const SELECT_TAB = "SELECT_TAB";

export const SELECT_BUSINESS = "SELECT_BUSINESS";

export const SAVING = "SAVING";

export const signinEvent = (user) => ({ type: SIGNIN_EVENT, user: user });
export const saveToken = (token) => ({ type: SAVE_TOKEN, token: token});

export const requestProfile = () => ({type: REQUEST_PROFILE });
export const receiveProfile = (profile) => ({type: RECEIVE_PROFILE, profile: profile});
export const receiveGeo = (geo, language) => ({type: RECEIVE_GEO, geo: geo, language: language});

export const requestDeals = () => ({type: REQUEST_DEALS});
export const receiveDeals = (deals) => ({type: RECEIVE_DEALS, deals: deals});

export const requestCode = (dealId) => ({type: REQUEST_CODE, dealId});
export const receiveCode = (deal, redemption) => ({type: RECEIVE_CODE, deal, redemption});

export const openStatusMessage = (message) => ({type: OPEN_STATUS_MESSAGE, message: message});
export const closeStatusMessage = () => ({type: CLOSE_STATUS_MESSAGE});
export const selectTab = (tab) => ({type: SELECT_TAB, tab: tab});

export const selectBusiness = (businessId) => ({type: SELECT_BUSINESS, businessId});

export const saving = (isSaving) => ({ type: SAVING, isSaving: isSaving });

export var backendUrl;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  backendUrl = 'http://localhost:8080/api/v1'; 
} else {
  // production code
  backendUrl = 'https://api.clubcoconut.co/api/v1'; 
}

ReactGA.initialize('UA-124195028-1', {
  gaOptions: {
    allowLinker: true,
  },
});
ReactPixel.init('280313106149692');

// Initialize Firebase
const config = {
  apiKey: "AIzaSyDQbnuLdFVlRUyNcHtwzZjjedadyzU0P8Q",
  authDomain: "clubcoconut-1.firebaseapp.com",
  // authDomain: "clubcoconut.co",
  databaseURL: "https://clubcoconut-1.firebaseio.com",
  projectId: "clubcoconut-1",
  storageBucket: "clubcoconut-1.appspot.com",
  messagingSenderId: "582821497834",
  appId: "1:582821497834:web:980d8a4b2bb9a71e330093",
  measurementId: "G-E6ZP30YNQC"
};
firebase.initializeApp(config);
// firebase.analytics();

function apiRequest(url, method="get", data=null, token=null) {
  let config = {
    url: `${backendUrl}${url}`,
    method: method,
    headers: {}
  }
  if(data) config.data = data;
  if(token) config.headers['Authorization'] = `Bearer ${token}`;
  return axios(config);
}

export function firebaseLogin() {
  return function(dispatch, getState) {
    // Setup phone authorization callbacks
    firebase.auth().onAuthStateChanged(function(user) {
      if(user) {
        dispatch(signinEvent(user));
        ReactGA.set({ userId: user.uid });
        ReactGA.event({
          category: "user",
          action: "login",
        });
        ReactPixel.track('Lead');
        
        user.getIdToken().then(function(idToken) {
          console.log("Firebase logged-in user");
          // console.log("Firebase userIdToken: " + idToken);
          dispatch(saveToken(idToken));
          dispatch(fetchProfile());
          // dispatch(fetchChannels());
          // setTimeout(() => { dispatch(fetchCompanies()); }, 1000);
          // setTimeout(() => { dispatch(fetchContacts()); }, 1000);
          // setTimeout(() => { dispatch(loadPrefs()); }, 1000);
        });
      } else {
        console.log("Not logged-in user");
        // dispatch(fetchProfile());
        dispatch(signinEvent(null));
      }

    });
  }
}

export function logout() {
  return function(dispatch) {
    // dispatch(localSignin(false));
    dispatch(signinEvent(null));
    console.log("Logging out: deleting locally stored profile");
    // del('profile', customStore);
    // TODO: should i also delete channels, etc?
    console.log("Logging out from firebase");
    firebase.auth().signOut();
  }
}

export function firebaseTokenRefresh() {
  return function(dispatch, getState) {
    console.log("Refreshing firebase token");
    var user = firebase.auth().currentUser;
    if(user) {
      user.getIdToken(true).then( idToken => {
          dispatch(saveToken(idToken));
      });

    } else {
      console.log("Can't refresh token, no currentUser on firebase");
    }
  }
}

export function fetchGeo() {
  return function(dispatch, getState) {
    console.log("Fetching geo and language");
    axios.get(`${backendUrl}/profile`)
      .then( json => {
        console.log(`fetchGeo received ${JSON.stringify(json.data)}`);
        dispatch(receiveGeo(json.data.geo, json.data.language));
      })
      .catch(function(error) {
        console.log("Could not fetch user geo due to exception");
        console.log(error);
      });
  }
}

export function fetchProfile() {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      dispatch(requestProfile());
      apiRequest("/profile", "get", null, token)
        .then( json => {
          dispatch(receiveProfile(json.data.user));
        })
        .catch( error => {
          console.log("Could not fetch user profile due to exception");
          console.log(error);
          dispatch(openStatusMessage("Could not connect."));
        });
    }
  }
}

export function fetchDeals(city="singapore", business=null, category=null) {
  return function(dispatch, getState) {
    dispatch(requestDeals());
    let url = `/deals/${city}`;
    if(business) {
      url += `/${business}`;
      if(category) {
        url += `/${category}`;
      }
    } else {
      if(category) {
        url += `/all/${category}`;
      }
    }
    console.log(`fetchDeals fetching url ${url}`);
    apiRequest(url, "get")
      .then( json => {
        dispatch(receiveDeals(json.data.deals));
      })
      .catch( error => {
        console.log("Could not fetch deals due to exception");
        console.log(error);
        dispatch(openStatusMessage("Could not connect"));
      });
  }
}

export function fetchDeal(dealId) {
  return function(dispatch, getState) {
    dispatch(requestDeals());
    apiRequest(`/deal/${dealId}`, "get")
      .then( json => {
        dispatch(receiveDeals([json.data.deal]));
      })
      .catch( error => {
        console.log("Could not fetch deals due to exception");
        console.log(error);
        dispatch(openStatusMessage("Could not connect"));
      });
  }
}

export function redeemDeal(dealId) {
  return function(dispatch, getState) {
    dispatch(requestCode(dealId));
    apiRequest(`/deal/${dealId}`, "post", null, getState().token)
      .then( json => {
        dispatch(receiveCode(json.data.deal, json.data.redemption));
      })
      .catch( error => {
        console.log("Could not redeem deal due to exception");
        console.log(error);
        if(error.response && "data" in error.response)
          dispatch(openStatusMessage(error.reponse.data.error));
      });
  }
}

export function saveProfile() {
  return function(dispatch, getState) {

  }
}

export function saveForLater(dealId, save=true) {
  return function(dispatch, getState) {
    let state = getState();
    let profile = state.profile;
    let token = state.token;

    if(token) {
      if(!profile.metadata) profile.metadata = {};
      if(!profile.metadata.saved) profile.metadata.saved = {};
      profile.metadata.saved[dealId] = save;
      dispatch(receiveProfile(profile));
      apiRequest("/profile", "put", profile, token)
        .then( json => {
          console.log(`Received profile from saveForLater ${JSON.stringify(json)}`);
          // dispatch(openStatusMessage("Saved for later"));
        })
        .catch( error => {
          console.log("Could not save for later due to exception");
          console.log(error);
          dispatch(openStatusMessage("Could not save promotion"));
        });
    } else {
      console.log("Won't save for later because not logged in");
    }
    
  }
}

export function saveBusiness(business) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(!business || !business.name) {
      dispatch(openStatusMessage("Please enter a name to save."));
      return;
    }

    if(token) {
      console.log(`Saving business ${JSON.stringify(business)}`);
      dispatch(openStatusMessage("Saving business..."));
      dispatch(saving(true));
      apiRequest("/businesses", "post", business, token)
        .then( json => {
          console.log("From saveBusiness, received json " + JSON.stringify(json));
          dispatch(openStatusMessage("Business saved."));
          dispatch(saving(false));
          dispatch(fetchProfile());
        })
        .catch( error => {
          console.log("Could not save business due to exception");
          console.log(error);
          dispatch(openStatusMessage("Could not save business"));
          dispatch(saving(false));
        });

    } else {
      console.log("Can't save business because logged out");
    }
  }
}


export function saveLocation(businessId, location) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(!location || !location.name) {
      dispatch(openStatusMessage("Please enter a name to save."));
      return;
    }

    if(token) {
      console.log(`Saving location ${JSON.stringify(location)} of business ${businessId}`);
      dispatch(openStatusMessage("Saving location..."));
      dispatch(saving(true));
      apiRequest(`/businesses/${businessId}/location`, "post", location, token)
        .then( json => {
          console.log("From saveLocation, received json " + JSON.stringify(json));
          dispatch(openStatusMessage("Location saved."));
          dispatch(saving(false));
          dispatch(fetchProfile());
        })
        .catch( error => {
          console.log("Could not save business location due to exception");
          console.log(error);
          dispatch(openStatusMessage("Could not save location"));
          dispatch(saving(false));
        });

    } else {
      console.log("Can't save location because logged out");
    }
  }
}

export function saveDeal(businessId, deal, images) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(!deal || !deal.name) {
      dispatch(openStatusMessage("Please enter a name to save."));
      return;
    }

    if(token) {
      console.log(`Saving deal ${JSON.stringify(deal)} of business ${businessId}`);
      dispatch(openStatusMessage("Saving promotion..."));
      dispatch(saving(true));

      var formData = new FormData();
      for(var i=0; i<images.length; i++) {
        console.log(`Adding image ${images[i].name} to multipart form for upload`);
        formData.append("images", images[i], images[i].name);
      }
      for(var k in deal) {
        formData.append(k, deal[k]);
      }

      const config = {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => console.log(`Progress: ${progressEvent.loaded} of ${progressEvent.total}`),
      }
      axios.post(`${backendUrl}/businesses/${businessId}/deal`, formData, config)
        .then( json => {
          console.log("From saveDeal, received json " + JSON.stringify(json));
          dispatch(openStatusMessage("Promotion saved."));
          dispatch(saving(false));
          dispatch(receiveDeals([json.data.deal]));
          dispatch(fetchProfile());
        })
        .catch( error => {
          console.log("Could not save promotion due to exception");
          console.log(error);
          dispatch(openStatusMessage("Could not save promotion"));
          dispatch(saving(false));
        });

    } else {
      console.log("Can't save deal because logged out");
    }
  }
}


