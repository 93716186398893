import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { logout } from '../store/actions';

class InnerSignout extends React.PureComponent {
  
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return <Redirect to='/' />;
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    geo: state.geo,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  }
}
const Signout = connect(mapStateToProps, mapDispatchToProps)(InnerSignout);

export default Signout;
