import React from 'react';
import { Link } from 'react-router-dom';

class NavBar extends React.PureComponent {
  render() {
    return (
      <nav className="navbar">
        <div className="feed" style={{textAlign: "left", display: "flex" }}>
          <div>
            <Link to="/" className="clean-link">
              <img src="/logo32x32.png" style={{float: "left", marginRight: 8}} width="32" height="32" />
              <span className="logo">CLUB COCO NUT Plus</span>
            </Link>
          </div>

          <div style={{ marginLeft: "auto", paddingRight: 8 }}>
            [<Link to="/merchant" className="std-link">
              post
            </Link>]
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
