import React from 'react';
import {
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { connect } from "react-redux";

import CssBaseline from '@material-ui/core/CssBaseline';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import WebFont from 'webfontloader';
import { Helmet } from "react-helmet";

import CircularProgress from '@material-ui/core/CircularProgress';
import './App.css';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

import NavBar from './nav/NavBar';
import StatusMessage from './nav/StatusMessage';
import NotFound from './utils/NotFound';
import Signin from './auth/Signin';
import Signout from './auth/Signout';
import Feed from './feed/Feed';
import DealPage from './feed/DealPage';
import Merchant from './merchant/Merchant';
import Business from './merchant/Business';
import Location from './merchant/Location';
import Deal from './merchant/Deal';
import EditDeal from './merchant/EditDeal';


/* Settings up Google Analytics */
// ReactGA.initialize('UA-124195028-1');
const history = createBrowserHistory();
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname + location.search); // not sure if right to add second
  ReactPixel.pageView();

});

/* Loading fonts */
WebFont.load({
  google: {
    families: ['Montserrat Alternates:400', 'Montserrat:400']
  }
});

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

theme.typography.h4.fontFamily = ["Montserrat", "-apple-system", '"Segoe UI"', "Roboto"].join(',');

const InnerPrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    // console.log(`All props ${JSON.stringify(props)}\nAll rest ${JSON.stringify(rest)}`);
    // console.log(`logging ${rest.isLoggingIn}, logged ${rest.isLoggedIn}`);
    if(rest.isLoggingIn === true) return (
      <div className="feed" style={{marginTop: 40}}>
        <CircularProgress />
      </div>
    );
    // console.log("Not logging in");
    if(rest.isLoggedIn === true) return <Component {...props} />;
    // console.log("Not logged in, PrivateRoute redirecting to login page");
    let loginUrl = `/login?next=${encodeURIComponent(window.location.href)}`;
    return <Redirect to={loginUrl} />;
  }} />
);


class InnerApp extends React.PureComponent {
  constructor() {
    super();
    // console.log("Landing page firing");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.pageView();
  }

  render() {
    return (
      <Router history={history}>
        <div className="App">
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />

            <Switch>
              <Route exact path="/" render={({history}) => <Feed history={history} />} />
              <Route path="/login" render={({history}) => <Signin history={history} />} />
              <Route path="/deal/:dealId" render={({ match, history }) => <DealPage match={match} history={history} />} />
              <Route path="/business/:businessId" render={({ match, history }) => <Feed match={match} history={history} />} />
              <PrivateRoute path="/logout" component={Signout} />
              <PrivateRoute exact path="/merchant" component={Merchant} />
              <PrivateRoute path="/merchant/business/new" component={Business} />
              <PrivateRoute path="/merchant/business/:businessId/new_location" component={Location} />
              <PrivateRoute path="/merchant/business/:businessId/new_deal" component={Deal} />
              <PrivateRoute path="/merchant/business/:businessId/deal/:dealId" component={EditDeal} />
              <Route render={() => <NotFound />} />
            </Switch>

            <StatusMessage />
          </ThemeProvider>

        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    profile: state.profile,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
  };
}

const App = connect(mapStateToProps)(InnerApp);
const PrivateRoute = connect(mapStateToProps)(InnerPrivateRoute);

export default App;
